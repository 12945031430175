import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: {
    nodeView: undefined,
    id: "",
    email: "",
    username: "",
    firstName: "",
    staffOrganization: [],
    notificationPreferances: {
      id: "",
      newMemberInConv: {
        inApp: false,
        email: false,
      },
      incomingMessages: {
        inApp: false,
        email: false,
      },
      mention: {
        inApp: false,
        email: false,
      },
    },
    activeOrgId: null,
    newOrganizationId: null,
  },
  error: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: (state) => Object.assign(state, initialState),
    setUserData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    updateStaffOrganizationUrl: (state, action) => {
      const { index, url } = action.payload;
      if (state.data.staffOrganization[index]) {
        state.data.staffOrganization[index].organization.domain = url;
      }
    },
    updateOrganizationActionCardStatus: (state, action) => {
      const { index, actionCardStatus } = action.payload;
      if (state.data.staffOrganization[index]) {
        state.data.staffOrganization[index].organization.actionCardFeature = actionCardStatus;
      }
    },
    updateOrganizationCueCardStatus: (state, action) => {
      const { index, cueCardStatus } = action.payload;
      if (state.data.staffOrganization[index]) {
        state.data.staffOrganization[index].organization.cueCardFeature = cueCardStatus;
      }
    },
    setUserLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const getActiveStaffOrg = (state) => {
  let activeStaffOrg = undefined;
  let { staffOrganization } = state.user.data;
  if (staffOrganization.length > 0) {
    activeStaffOrg = staffOrganization[0];
  }
  return activeStaffOrg;
};

export const getActiveOrg = (state) => {
  return getActiveStaffOrg(state)?.organization;
};

export const getActiveOrgId = (state) => {
  return getActiveOrg(state)?.id;
};

export const { resetUser, setUserData, updateStaffOrganizationUrl,updateOrganizationActionCardStatus,updateOrganizationCueCardStatus, setUserLoading } = userSlice.actions;
export default userSlice.reducer;
