import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { message, Modal, Select, Tag,Button } from "antd";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";

import { useQuery } from "@apollo/client";

import { createLabel } from "src/api/LabelApi";
import DraggableTag from "../DraggableTag/DraggableTag";
import styles from "./SelectOptions.module.scss";
import { GET_ORGANIZATION_LABELS } from "src/graphql/contact";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { useTranslation } from "react-i18next";

function generateDarkColor() {
  var hex = "#";

  // Generate a random dark shade
  var red = Math.floor(Math.random() * 128);
  var green = Math.floor(Math.random() * 128);
  var blue = Math.floor(Math.random() * 128);

  // Convert RGB to hexadecimal representation
  hex += componentToHex(red);
  hex += componentToHex(green);
  hex += componentToHex(blue);

  return hex;
}

function componentToHex(color) {
  var hex = color.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

// const darkColor = generateDarkColor();

const SelectOptions = ({
  reftechData,
  allRefetch,
  item,
  handleMultiselectFetch,
  handleMultiSelectChange,
  isTableElement,
}) => {
  // const randomColor = "#" + Math.random().toString(16).substr(-6);
  const { t } = useTranslation();
  const divRef = useRef(null);
  const [color, setColor] = useState(generateDarkColor());

  const organizationId = useSelector(getActiveOrgId);

  const selectRef = useRef(null);

  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [isDropdown, setIsDropdown] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const showMoreModal = () => {
    setIsModalOpen(true);
  };
  const handleMoreOk = () => {
    setIsModalOpen(false);
  };
  const handleMoreCancel = () => {
    // handleInputChangeState(inputValue)
    setIsModalOpen(false);
  };

  const handleDropdownVisible = (e) => {
    // const containerRect = divRef.current.getBoundingClientRect();
    // const { clientX, clientY } = e;
    const selectElement = divRef.current;
    if (selectElement) {
      const selectRect = selectElement.getBoundingClientRect();
      const { top, left } = selectRect;
      setModalPosition({ left: left - 50, top: top - 150 });
    }
    refetch();
    setIsDropdown(!isDropdown);
    setIsModalOpen(true);
  };

  const {
    data: labelAttribute,
    loading: attributeLoading,
    error: attributeError,
    refetch,
  } = useQuery(GET_ORGANIZATION_LABELS, {
    variables: {
      organizationId: organizationId,
      multiselectId: item?.id,
    },
    skip: !organizationId || !item,
    fetchPolicy: "cache-and-network",
  });

  const fillMultiSelectField = (tags) => {
    const currentSelectedOptions = [];
    item?.options?.map((tx) => {
      const index = tags.findIndex((t) => t?.id === tx?.id);
      if (index !== -1) {
        currentSelectedOptions.push(tags[index]);
      }
    });
    setSelectedItems(currentSelectedOptions);
  };

  useEffect(() => {
    if (!labelAttribute) {
      return;
    }
    let tags = [];
    if (labelAttribute?.getOrganization?.multiSelectAttribute.length > 0) {
      labelAttribute?.getOrganization?.multiSelectAttribute[0].labels?.map(
        (tx) => {
          tags.push({
            ...tx,
            label: tx?.name,
            value: tx?.id,
          });
        }
      );
      setOptions(tags);
      if (item?.isEdit === true) {
        fillMultiSelectField(tags);
      }
      setFilteredOptions(tags);
    }
  }, [item, labelAttribute]);

  useEffect(() => {
    if (!item) {
      return;
    }
    let tags = [];
    if (item?.isEdit === true && item.options?.length == 0) {
      fillMultiSelectField(tags);
    }
  }, [item]);

  // const handleMultiSelectChangeMemoized = useCallback(handleMultiSelectChange, []);

  //   useEffect(() => {
  //     let res = options?.map((item) => ({
  //         ...item,
  //         isSelected: selectedItems.findIndex((tx) => tx?.id === item?.id) !== -1 ? true : false
  //     }));
  //     handleMultiSelectChangeMemoized(item.id, res);
  // }, [options, selectedItems, handleMultiSelectChangeMemoized]);

  const handleTagClick = useCallback(
    (val) => {
      let isFoundIndex = selectedItems.findIndex((tx) => tx?.id === val?.id);
      if (isFoundIndex !== -1) {
        return;
      }
      let result;
      result = [...selectedItems, val];
      let res = options?.map((item) => ({
        ...item,
        isSelected:
          result.findIndex((tx) => tx?.id === item?.id) !== -1 ? true : false,
      }));
      handleMultiSelectChange(item.id, res);
      setSelectedItems([...selectedItems, val]);
    },
    [selectedItems, options]
  );

  const handleSelectSearch = (val) => {
    let res = options.filter((option) =>
      option.name.toLowerCase().includes(val.toLowerCase())
    );
    setFilteredOptions(res);
    if (val === "") {
      setColor(generateDarkColor());
    }

    setSearch(val);
  };

  const handleCreateTag = async () => {
    if (search == "") {
      return;
    }
    try {
      const data = {
        name: search,
        color: color,
        multiSelectAttributeId: item?.id,
      };
      const res = await createLabel(data);
      if (res?.data?.success === false) {
        throw new Error(res?.data?.message);
      }
      const createdLabel = res.data.label;
      setSearch("");
      if (res.data.event === "label updated") {
        message.success(
          "This label already exists, linking with this attribute"
        );
      }
      if (!createLabel) {
        return;
      }
      let result = [
        ...selectedItems,
        {
          id: createdLabel.id,
          color: createdLabel.color,
          name: createdLabel.name,
          label: createdLabel.name,
          value: createdLabel.id,
        },
      ];
      let resOptions = [
        ...options,
        {
          id: createdLabel.id,
          color: createdLabel.color,
          name: createdLabel.name,
          label: createdLabel.name,
          value: createdLabel.id,
        },
      ];
      let rest = resOptions?.map((item) => ({
        ...item,
        isSelected:
          result.findIndex((tx) => tx?.id === item?.id) !== -1 ? true : false,
      }));
      handleMultiSelectChange(item.id, rest);
      setOptions(resOptions);
      setFilteredOptions([
        ...options,
        {
          id: createdLabel.id,
          color: createdLabel.color,
          name: createdLabel.name,
          label: createdLabel.name,
          value: createdLabel.id,
        },
      ]);
      setSelectedItems(result);
      setColor(generateDarkColor());
      refetch();
    } catch (err) {
      console.log(err);
      message.error(err.message);
    }
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        color={options?.filter((item) => item.value === value)[0]?.color}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  const tagRenderClose = (props) => {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={false}
        onClose={onClose}
        color={options?.filter((item) => item.value === value)[0]?.color}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  const handleFetchOptions = (v) => {
    setOptions(v);
    setFilteredOptions(v);
  };

  const handleSelectChange = (e) => {
    if (Array.isArray(e)) {
      let currentSelectedItems = selectedItems.filter((tx) =>
        e.includes(tx?.id)
      );
      let res = options?.map((item) => ({
        ...item,
        isSelected:
          currentSelectedItems.findIndex((tx) => tx?.id === item?.id) !== -1
            ? true
            : false,
      }));
      handleMultiSelectChange(item.id, res);
      setSelectedItems(currentSelectedItems);
    }
  };

  const memoizedOptions = useMemo(() => options, [options]);
  const memoizedFilteredOptions = useMemo(
    () => filteredOptions,
    [filteredOptions]
  );
  console.log(memoizedFilteredOptions)

  return (
    <div
      className={styles.SelectOptions}
      ref={divRef}
      style={{ position: "relative",padding:"10px",whiteSpace: 'pre-line',display:'flex',alignItems: 'baseline', gap:'8px' }}
    >
      {memoizedFilteredOptions.map((value, index) => (
          // <SortableItem key={`item-${value?.id}`} index={index} value={value} />
          <Tag
          color={value.color}
          style={{borderRadius:'32px', height:'22px'}}
          shape="round"
          className={styles.SelectOptions__container__tags}
          // closable
          >
            {value.name}
            
          </Tag>
        ))}
        
        <Button 
        onClick={(e) => handleDropdownVisible(e)}
        size="small"
        shape="round" type="dashed"><PlusOutlined />New Tag</Button>
    
      <div className={styles.SelectOptions__container}>
        <Modal
          mask={false}
          open={isModalOpen}
          onOk={handleMoreOk}
          onCancel={handleMoreCancel}
          // maskClosable={false}
          footer={null}
          closeIcon={false}
          className={styles.SelectOptions__container__modal}
          wrapClassName={styles.SelectOptions__container__wrapmodal}
          width={250}
          style={{ position: "absolute", ...modalPosition }}
        >
          <Select
            mode="multiple"
            placeholder={t("Enter your new tag name..")}
            required={false}
            // value={selectedItems}
            onSearch={handleSelectSearch}
            onChange={(e) => handleSelectChange(e)}
            popupClassName={styles.SelectOptions__container__popup}
            tagRender={tagRender}
            style={{
              width: "100%",
            }}
            options={memoizedFilteredOptions}
          />
          <div className={styles.SelectOptions__container__options}>
            <p>{t("Select from the existing list")}</p>
            {memoizedFilteredOptions?.length !== 0 ? (
              <div className={styles.SelectOptions__container__options__items}>
                <div
                  className={
                    styles.SelectOptions__container__options__items__item
                  }
                >
                  <DraggableTag
                    reftechData={reftechData}
                    allRefetch={allRefetch}
                    handleTagClick={handleTagClick}
                    tags={memoizedFilteredOptions}
                    selectedItems={selectedItems}
                    handleFetchOptions={handleFetchOptions}
                    organizationId={organizationId}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.SelectOptions__container__options__create}>
                <span style={{ color: "black" }}>{t("Create")}</span>
                <Tag
                  onClick={() => handleCreateTag()}
                  className={
                    styles.SelectOptions__container__options__create__tag
                  }
                  color={color}
                >
                  {search}
                </Tag>
              </div>
            )}
          </div>
        </Modal>
      </div>
      {/* )} */}
    </div>
  );
};

export default React.memo(SelectOptions);
