import React, { useState, useEffect, useRef } from "react";
import { activityLog, fetchNodetimer } from "src/api/TimesheetApi";
import { node } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  resetTimer as reduxResetTimer,
  setGloabalTimerRunning as reduxGlobalTimer,
  incrementElapsedTime,
  setTotalElapsedTime,
  setNodeTimers,
  setLoadingTimers,
  setErrorFetchingTimers,
  setGlobalTimerRunning,
} from "src/store/slices/timerSlice";
import { convertDurationToSeconds } from "src/helpers/timerConfig";
import { initializeActivityState } from "src/helpers/initializeActivity";
// import TimerWorker from "../NodeShell/timerWorker";
import WebWorker from "src/WebWorkers";
import timerWorker from "src/helpers/timerWorker";
import logActivityWorker from "src/helpers/logActivityWorker"
import sendDataToBackendWorker from "src/helpers/sendDataToBackendWorker"
import { UPDATE_TIMER_STATUS } from "src/graphql/timer";
import {  useMutation } from "@apollo/client";

let workerRef = new WebWorker(timerWorker);
let logActivityWorkerRef = new WebWorker(logActivityWorker);
let sendDataToBackendWorkerRef = new WebWorker(sendDataToBackendWorker);

const withBackgroundTimer = (WrappedComponent) => {
  const WithBackgroundTimer = (props) => {
    const [activityQueue, setActivityQueue] = useState(initializeActivityState);
    const [syncData, setSyncData] = useState();
    const [sending, setSending] = useState(false);
    const nodeTimersRef = useRef({});
    const dispatch = useDispatch();
    const {selectedNode,nodeId} = useSelector((state) => state?.node?.data);
    const {nodeTimers,globalTimerRunning} = useSelector((state) => state?.timer?.data);
    const { email,username } = useSelector((state) => state?.user?.data);
    const userData = useSelector((state) => state?.user?.data);
    const staffId = userData?.id;
    const [isOnline, setIsOnline] = useState(true);
    const [updateTimerStatus] = useMutation(UPDATE_TIMER_STATUS);


    useEffect(() => {
      if (workerRef) {
        workerRef.terminate();
        workerRef = new WebWorker(timerWorker);
      }
      if (logActivityWorkerRef) {
        logActivityWorkerRef.terminate();
        logActivityWorkerRef = new WebWorker(logActivityWorker)
      }
      logActivityWorkerRef.onmessage = function(e) {
        if (e.data === 'log') {
          logActivity();
        }
        if(e.data === 'logging stopped'){
          console.log("stop logging")
        }
      }
     
    }, [globalTimerRunning,selectedNode]);

    useEffect(() => {
      const handleUnload = async (e) => {
        e.preventDefault()
      sendDataToBackend()

        dispatch(
          setGlobalTimerRunning(false)
        );
        updateTimerStatus({ variables: { staffId, timerStatus: false } });

      };
      window.addEventListener("beforeunload", handleUnload);
      return () => window.removeEventListener("beforeunload", handleUnload);
    }, []);

    useEffect(() => {
      localStorage.setItem("activityQueue", JSON.stringify(activityQueue));
    }, [activityQueue]);

    useEffect(() => {
      const savedQueue = localStorage.getItem("activityQueue");
      if (savedQueue) {
        setActivityQueue(JSON.parse(savedQueue));
      }
    }, []);
    useEffect(() => {
      const handleOnlineStatusChange = () => {
        if (navigator.onLine) {
          setIsOnline(true);
          sendDataToBackend();
        } else {
          setIsOnline(false);
        }
      };
  
      window.addEventListener("online", handleOnlineStatusChange);
      window.addEventListener("offline", handleOnlineStatusChange);
  
      return () => {
        window.removeEventListener("online", handleOnlineStatusChange);
        window.removeEventListener("offline", handleOnlineStatusChange);
      };
    }, []);

    let tick = 0;
    useEffect(() => {
      workerRef.onmessage = function (e) {
        if (e.data === 'tick') {
          tick += 1;
          const updatedNodeTimers = {
            ...nodeTimers,
            [selectedNode.uniqueKey]: (nodeTimers[selectedNode.uniqueKey] || 0) + tick,
          };
          dispatch(setNodeTimers(updatedNodeTimers));
        }
      };
     
    }, [nodeTimers,globalTimerRunning, selectedNode]);

    useEffect(() => {
      if (globalTimerRunning) {
        workerRef.postMessage('start');
        logActivityWorkerRef.postMessage('start');
        sendDataToBackendWorkerRef.postMessage('sendData'); 
      } else {
        workerRef.postMessage('stop');
        logActivityWorkerRef.postMessage('stop');
      }
    }, [globalTimerRunning,selectedNode]);

    useEffect(() => {
      sendDataToBackendWorkerRef.onmessage = function (e) {
        if (e.data === 'send') {
          sendDataToBackend()
        }
      }
    }, []);

    useEffect(() => {
      const total = Object.values(nodeTimers).reduce((acc, timer) => acc + timer, 0);
      dispatch(setTotalElapsedTime(total));
    }, [nodeTimers, globalTimerRunning, selectedNode]);

    const logActivity = () => {
      if (!globalTimerRunning) {
        return;
      }
      const tzoffset = new Date().getTimezoneOffset() * 60000;
      const startTime = new Date(Date.now() - tzoffset).toISOString();
      const endTime = new Date(Date.now() + 10000 - tzoffset).toISOString();

      const activity = {
        staffId: staffId,
        email: email,
        activityType: "WEB_ACTIVITY",
        nodeId: nodeId,
        username: username,
        startTime: startTime,
        endTime: endTime,
      };

      setActivityQueue((prevQueue) => {
        if (syncData && prevQueue) {
          const updatedQueue = prevQueue.filter(
            (item) => new Date(item.startTime) > new Date(syncData.startTime)
          );
          return [...updatedQueue, activity];
        } else {
          return [...(prevQueue || []), activity];
        }
      });
    };

    const sendDataToBackend = async () => {
      if (sending) {
        return;
      }
      try {
        setSending(true);
        const queueData = JSON.parse(localStorage.getItem("activityQueue") || "[]");

        if (queueData.length > 0) {
          await activityLog(queueData);

          const lastItem = queueData[queueData.length - 1];
          setSyncData(lastItem);

          setActivityQueue((prevQueue) => {
            if (lastItem && prevQueue) {
              const updatedQueue = prevQueue.filter(
                (item) => new Date(item.startTime) > new Date(lastItem.startTime)
              );
              return [...updatedQueue];
            } else {
              return [...(prevQueue || [])];
            }
          });
        }
      } catch (error) {
        console.error("Error sending data to backend:", error);
      } finally {
        setSending(false);
      }
    };

    return (
      <WrappedComponent
        {...props}
        nodeTimersRef={nodeTimersRef}
        sendDataToBackend={sendDataToBackend}
      />
    );
  };

  return WithBackgroundTimer;
};

export default withBackgroundTimer;
